import { FavoriteQuery, FavoriteQueryDraft } from '../types/favoriteQuery';
import baseApi from './baseApi';

const favoriteQueriesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFavoriteQueries: build.query({
      query: () => ({
        url: '/admin/favoriteQueries',
        method: 'GET',
      }),
      transformResponse: (response: FavoriteQuery[]): FavoriteQuery[] => response,
    }),
    createFavoriteQuery: build.mutation({
      query: (body: FavoriteQueryDraft) => ({
        url: '/admin/favoriteQueries',
        method: 'POST',
        body,
      }),
      transformResponse: (response: FavoriteQuery): FavoriteQuery => response,
    }),
    deleteFavoriteQuery: build.mutation({
      query: (favoriteQueryId: number) => ({
        url: `/admin/favoriteQueries/${favoriteQueryId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: FavoriteQuery): FavoriteQuery => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetFavoriteQueriesQuery, useCreateFavoriteQueryMutation, useDeleteFavoriteQueryMutation } =
  favoriteQueriesApi;
