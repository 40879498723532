import React, { FC } from 'react';

import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';

import { useGetBusinessUnitSourcesQuery } from 'services/api/user.api';

interface SourceProps {
  sources: string[];
  setSources: (sources: string[]) => void;
  businessUnitId: number | null;
}

const SourcesSelector: FC<SourceProps> = ({ sources, setSources, businessUnitId }) => {
  const { data: sourcesData } = useGetBusinessUnitSourcesQuery({ businessUnitId });
  const handleChange = (event: SelectChangeEvent<typeof sources>) => {
    const {
      target: { value },
    } = event;
    setSources(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  if (!sourcesData) {
    return null;
  }

  return (
    <FormControl sx={{ minWidth: 250 }}>
      <InputLabel>Origen</InputLabel>
      <Select
        label="Origen"
        multiple
        value={sources}
        onChange={handleChange}
        renderValue={(selected) =>
          selected
            .map((value) => (value.length > 33 ? `${value.slice(0, 15)}...${value.slice(-15)}` : value))
            .join(', ')
        }
        autoWidth
      >
        {[...sourcesData] // need to create a copy of the array to avoid mutating the original
          .filter((source) => !!source)
          .sort((a, b) => a.localeCompare(b))
          .map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={sources.indexOf(name) > -1} />
              <ListItemText primary={name || '--desconocido--'} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SourcesSelector;
