import React, { FC, useEffect } from 'react';

import FilterAlt from '@mui/icons-material/FilterAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import RelifIcon from 'assets/images/relif_icon.svg';
import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import InfoDialog from 'components/UI/InfoDialog';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import OptionsDialog from 'components/UI/OptionsDialog';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  useGetCreditStatusMutation,
  useUpdateEditableAttributesMutation,
  useUploadClientToCRMMutation,
} from 'services/api/clients.api';
import { Client } from 'services/types/client';

import TemplateMessageModal from './TemplateMessageModal';

interface Props {
  client: Client;
  refetch: () => void;
  setIsFilteredConversation: (isFiltered: boolean) => void;
  isFilteredConversation: boolean;
}

const ChatHeader: FC<Props> = ({ client, refetch, setIsFilteredConversation, isFilteredConversation }) => {
  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openScheduleTemplateModal, setOpenScheduleTemplateModal] = React.useState(false);
  const [openUploadToCRMModal, setOpenUploadToCRMModal] = React.useState(false);
  const [openCRMError, setOpenCRMError] = React.useState(false);
  const [CRMUploadError, setCRMUploadError] = React.useState<null | string>(null);
  const [openCRMSuccess, setOpenCRMSuccess] = React.useState(false);
  const [updateEditableAttributes] = useUpdateEditableAttributesMutation();
  const [getCreditStatus, { data: creditStatus }] = useGetCreditStatusMutation();
  const [uploadClientToCRM, { isLoading: isUploadClientToCRMLoading }] = useUploadClientToCRMMutation();

  const uploadableBusinessIds = [2, 3, 8, 10, 11, 14, 16, 17, 18, 19, 20, 21, 24];

  const openDropdownMenu = Boolean(anchorEl);
  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleEnableAutomaticResponses = () => {
    updateEditableAttributes({
      id: client.id,
      body: { disabledAutomaticResponses: !client.disabledAutomaticResponses },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error updating client');
      });
  };

  const handleGetCreditStatus = () => {
    getCreditStatus(client.id)
      .then(() => {
        refetch();
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error getting credit status');
      });
  };

  const handleUploadToCRM = () => {
    if (!isUploadClientToCRMLoading) {
      uploadClientToCRM({ clientId: client.id, businessUnitId: client.businessUnitId })
        .then((data) => {
          console.log(data); // eslint-disable-line no-console
          setOpenUploadToCRMModal(false);
          if ('error' in data) {
            setOpenCRMError(true);
            if ('data' in data.error && typeof data.error.data === 'string') {
              setCRMUploadError(data.error.data);
            }
          } else {
            setOpenCRMSuccess(true);
            refetch();
          }
        })
        .catch(() => {
          setOpenUploadToCRMModal(false);
          setOpenCRMError(true);
          setCRMUploadError('Error subiendo cliente al CRM');
        });
    }
  };

  useEffect(() => {
    if (creditStatus) {
      alert(JSON.stringify(creditStatus)); // eslint-disable-line no-alert
    }
  }, [creditStatus]);

  return (
    <Grid item xs={12} px={2}>
      <LoadingOverlay visible={isUploadClientToCRMLoading} />
      <TemplateMessageModal
        clientId={client.id}
        open={openScheduleTemplateModal}
        setOpen={setOpenScheduleTemplateModal}
        refetch={refetch}
        schedule={false}
      />
      <OptionsDialog
        openDialog={openUploadToCRMModal && !isUploadClientToCRMLoading}
        setOpenDialog={setOpenUploadToCRMModal}
        header="Subir lead al CRM"
        description="¿Estás seguro que deseas subir este lead al CRM?"
      >
        <PrimaryBtn color="error" onClick={() => setOpenUploadToCRMModal(false)}>
          Cancelar
        </PrimaryBtn>
        <PrimaryBtn onClick={handleUploadToCRM}>Subir</PrimaryBtn>
      </OptionsDialog>
      <AlertDialog
        openDialog={openCRMError}
        setOpenDialog={setOpenCRMError}
        header="Error"
        msg={CRMUploadError || 'Error subiendo cliente al CRM'}
        cancelMsg="Cerrar"
      />
      {openCRMSuccess && <InfoDialog header="Cliente subido al CRM" msg="Cliente subido al CRM con éxito!" />}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Tooltip title="Recargar conversación" arrow>
            <IconButton onClick={refetch} size="large">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <IconButton onClick={handleDropdownClick}>
            <MoreVertIcon />
          </IconButton>

          <Menu
            open={openDropdownMenu}
            onClose={handleDropdownClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenScheduleTemplateModal(true);
              }}
            >
              Enviar Template
            </MenuItem>
            <MenuItem onClick={handleEnableAutomaticResponses}>
              {client.disabledAutomaticResponses ? 'Habilitar' : 'Deshabilitar'} IA
            </MenuItem>
            {client.creditSimulations && client.creditSimulations.length > 0 && (
              <MenuItem onClick={handleGetCreditStatus}>Estado de simulación de crédito</MenuItem>
            )}
            {uploadableBusinessIds.includes(client.businessUnitId) && (
              <MenuItem onClick={() => setOpenUploadToCRMModal(true)}>Subir al CRM</MenuItem>
            )}
          </Menu>
          {currentUser?.isAdmin && (
            <Tooltip title="Filtrar conversación" arrow>
              <IconButton onClick={() => setIsFilteredConversation(!isFilteredConversation)} size="large">
                <FilterAlt
                  sx={{
                    color: isFilteredConversation ? 'primary.main' : 'inherit',
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {currentUser?.isAdmin && client.customAttributes && client.customAttributes.relifId && (
            <IconButton
              size="large"
              onClick={() => {
                if (!client.customAttributes) {
                  return;
                }
                window.open(
                  `${import.meta.env.VITE_RELIF_URL}/admin/users?selectedUserId=${
                    client.customAttributes.relifId
                  }&userId=${client.customAttributes.relifId}`,
                  '_blank'
                );
              }}
            >
              <img src={RelifIcon} alt="Relif" width={30} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ChatHeader;
