import React, { FC } from 'react';

import { Box, TextField } from '@mui/material';

import { SearchIcon } from 'assets/icons';
import BusinessUnitSelector from 'components/common/BusinessUnitsSelector';

interface Props {
  searchTerm: string;
  onSearchTermChange: (searchTerm: string) => void;
  businessUnitId: number | null;
  setBusinessUnitId: (businessUnitId: number) => void;
}

const SearchBar: FC<Props> = ({ searchTerm, onSearchTermChange, businessUnitId, setBusinessUnitId }) => {
  return (
    <Box display={{ xs: 'block', md: 'flex' }}>
      <TextField
        type="text"
        placeholder="Buscar"
        onChange={(e) => onSearchTermChange(e.target.value)}
        value={searchTerm}
        sx={{
          py: { xs: 1, md: 0 },
          mx: { xs: 0, sm: 2 },
          minWidth: '200px',
          '& .MuiInputBase-input': {
            fontSize: '14px',
            color: 'text.secondary',
            fontWeight: 'bold',
          },
          '& .MuiInputBase-root': {
            border: '1px solid #E5E5E5',
            borderRadius: '10px',
            padding: '0 10px',
          },
          backgroundColor: 'primary.contrastText',
        }}
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
      />
      <BusinessUnitSelector businessUnitId={businessUnitId} setBusinessUnitId={setBusinessUnitId} selectItemIfOnlyOne />
    </Box>
  );
};

export default SearchBar;
