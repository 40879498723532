import React, { FC } from 'react';

import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';

import { useGetBusinessUnitBrandsQuery } from 'services/api/user.api';

interface BrandsSelectorProps {
  brands: string[];
  setBrands: (sources: string[]) => void;
  businessUnitId: number | null;
}

const BrandsSelector: FC<BrandsSelectorProps> = ({ brands, setBrands, businessUnitId }) => {
  const { data: brandsData } = useGetBusinessUnitBrandsQuery({ businessUnitId });
  const handleChange = (event: SelectChangeEvent<typeof brands>) => {
    const {
      target: { value },
    } = event;
    setBrands(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  if (!brandsData) {
    return null;
  }

  return (
    <FormControl sx={{ minWidth: 250, pl: 1 }}>
      <InputLabel>Marca</InputLabel>
      <Select
        label="Marca"
        multiple
        value={brands}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        autoWidth
      >
        {[...brandsData] // need to create a copy of the array to avoid mutating the original
          .filter((brand) => !!brand)
          .sort((a, b) => (a === 'desconocido' ? 1 : a.localeCompare(b)))
          .map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={brands.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default BrandsSelector;
