import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactsIcon from '@mui/icons-material/Contacts';
import Dashboard from '@mui/icons-material/Dashboard';
import Logout from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import PollIcon from '@mui/icons-material/Poll';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import StorageIcon from '@mui/icons-material/Storage';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import { ProfileCircleIcon, CarIcon, CloseIcon, MenuIcon } from 'assets/icons';
import logo from 'assets/images/logo.png';
import useCurrentRoute from 'hooks/useCurrentRoute';
import useCurrentUser from 'hooks/useCurrentUser';
import useIsMobile from 'hooks/useIsMobile';
import { signOutUser } from 'redux/slices/user.slice';

export const drawerWidth = 250;

const SideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: 'min(8vh, 56px)',
  color: theme.palette.primary.main,
  backgroundColor: '#fff',
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '16px',
  transition: 'all 0.15s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    '& span': {
      fontSize: '22px !important',
      color: 'inherit',
      fontWeight: 400,
    },
  },
  '& span': {
    fontSize: '18px !important',
    color: 'inherit',
    fontWeight: 400,
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const CurrentSideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: 'min(8vh, 56px)',
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '16px',
  transition: 'all 0.15s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    '& span': {
      fontSize: '22px !important',
      color: 'inherit',
      fontWeight: 600,
    },
  },
  '& span': {
    fontSize: '18px !important',
    color: 'inherit',
    fontWeight: 600,
  },
  '&:hover': {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    opacity: '0.8',
    '& span': {
      color: '#fff',
    },
  },
}));

interface SideBarItemInterface {
  text: string;
  Icon: React.ReactNode;
  isSelected: boolean;
  path:
    | '/'
    | '/users'
    | '/clients'
    | '/businessUnit'
    | '/analysis'
    | '/businessUnits'
    | '/dashboard'
    | '/db-reader'
    | '/cars'
    | '/evals'
    | '/information'
    | '/clientQuestions'
    | '/campaigns'
    | '/profile';
}

const SignOutButton: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(signOutUser());
    navigate('/');
  };

  return (
    <ListItem sx={{ px: '16px' }}>
      <SideBarItemButton onClick={handleClick} sx={{ px: 2 }}>
        <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </SideBarItemButton>
    </ListItem>
  );
};

interface SideBarButtonInterface extends SideBarItemInterface {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBarButton: React.FC<SideBarButtonInterface> = ({ text, Icon, isSelected, path, setMobileOpen }) => {
  const { isMobile } = useIsMobile();
  return (
    <Link
      to={path}
      onClick={() => {
        if (isMobile) {
          setMobileOpen(false);
        }
      }}
      style={{ textDecoration: 'none' }}
    >
      <ListItem sx={{ px: '16px', py: '4px' }}>
        {isSelected ? (
          <CurrentSideBarItemButton sx={{ px: 2 }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>{Icon}</ListItemIcon>
            <ListItemText primary={text} />
          </CurrentSideBarItemButton>
        ) : (
          <SideBarItemButton sx={{ px: 2 }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>{Icon}</ListItemIcon>
            <ListItemText primary={text} />
          </SideBarItemButton>
        )}
      </ListItem>
    </Link>
  );
};

const SideBar = () => {
  const { path } = useCurrentRoute();
  const currentUser = useCurrentUser();
  const isAdmin = !!currentUser?.isAdmin;
  const isBusinessUnitAdmin = isAdmin || (!!currentUser?.canAssignClient && !currentUser?.isAssignable);
  const isBranchManager = isBusinessUnitAdmin || (!!currentUser?.canAssignClient && !!currentUser?.isAssignable);

  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sellersSections: SideBarItemInterface[] = [
    {
      text: 'Clientes',
      Icon: <ContactsIcon />,
      path: '/clients',
      isSelected: path.includes('/clients'),
    },
    {
      text: 'Mi Perfil',
      Icon: <ProfileCircleIcon />,
      path: '/profile',
      isSelected: path.includes('/profile'),
    },
  ];

  const branchManagerSections: SideBarItemInterface[] = [
    {
      text: 'Dashboard',
      Icon: <Dashboard />,
      isSelected: path.includes('/dashboard'),
      path: '/dashboard',
    },
    {
      text: 'Análisis (βeta)',
      Icon: <AutoGraphIcon />,
      path: '/analysis',
      isSelected: path.includes('/analysis'),
    },
    {
      text: 'Usuarios',
      Icon: <PeopleIcon />,
      path: '/users',
      isSelected: path.includes('/users'),
    },
  ];

  const businessUnitAdminSections: SideBarItemInterface[] = [
    {
      text: 'Ajustes',
      Icon: <TuneIcon />,
      path: '/information',
      isSelected: path.includes('/information'),
    },
  ];

  const adminSections: SideBarItemInterface[] = [
    {
      text: 'Empresas',
      Icon: <Dashboard />,
      path: '/businessUnits',
      isSelected: path.includes('/businessUnits'),
    },
    {
      text: 'Autos',
      Icon: <CarIcon />,
      path: '/cars',
      isSelected: path.includes('/cars'),
    },
    {
      text: 'DB reader',
      Icon: <StorageIcon />,
      path: '/db-reader',
      isSelected: path.includes('/db-reader'),
    },
    {
      text: 'Evals',
      Icon: <PollIcon />,
      path: '/evals',
      isSelected: path.includes('/evals'),
    },
    {
      text: 'Preguntas',
      Icon: <QuestionMarkIcon />,
      path: '/clientQuestions',
      isSelected: path.includes('/clientQuestions'),
    },
    {
      text: 'Campañas',
      Icon: <CampaignIcon />,
      path: '/campaigns',
      isSelected: path.includes('/campaigns'),
    },
  ];

  const drawer = (
    <div>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'right', mr: 2, mt: 2 }}>
        <IconButton aria-label="open drawer" onClick={handleDrawerToggle} color="inherit">
          <CloseIcon />
        </IconButton>
      </Box>
      <Toolbar
        sx={{
          py: { xs: '26px', xl: 6 },
          px: '0px !important',
          height: { xs: 'min(100px, 14vh)', xl: '138px' },
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/');
        }}
      >
        <img src={logo} alt="logo" style={{ width: '130%', maxWidth: '240px' }} />
      </Toolbar>
      <List sx={{ pb: '10px' }}>
        {sellersSections.map((section) => (
          <SideBarButton
            Icon={section.Icon}
            text={section.text}
            key={section.text}
            isSelected={section.isSelected}
            path={section.path}
            setMobileOpen={setMobileOpen}
          />
        ))}
      </List>
      {isBranchManager && (
        <>
          <Divider sx={{ width: '75%', mx: 'auto' }} />
          <List>
            {branchManagerSections.map((section) => (
              <SideBarButton
                Icon={section.Icon}
                text={section.text}
                key={section.text}
                isSelected={section.isSelected}
                path={section.path}
                setMobileOpen={setMobileOpen}
              />
            ))}
          </List>
        </>
      )}
      {isBusinessUnitAdmin && (
        <>
          <Divider sx={{ width: '75%', mx: 'auto' }} />
          <List>
            {businessUnitAdminSections.map((section) => (
              <SideBarButton
                Icon={section.Icon}
                text={section.text}
                key={section.text}
                isSelected={section.isSelected}
                path={section.path}
                setMobileOpen={setMobileOpen}
              />
            ))}
          </List>
        </>
      )}
      {isAdmin && (
        <>
          <Divider sx={{ width: '75%', mx: 'auto' }} />
          <List>
            {adminSections.map((section) => (
              <SideBarButton
                Icon={section.Icon}
                text={section.text}
                key={section.text}
                isSelected={section.isSelected}
                path={section.path}
                setMobileOpen={setMobileOpen}
              />
            ))}
          </List>
        </>
      )}
      <SignOutButton />
    </div>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          mr: 2,
          display: { xs: 'block', md: 'none' },
          position: 'fixed',
          top: '20px',
          left: `25px`,
          zIndex: '100',
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box component="nav" sx={{ width: { xs: '100%', md: drawerWidth }, flexShrink: { sm: 0 } }}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              border: 0,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default SideBar;
