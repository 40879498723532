import React, { FC, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import InfoDialog from 'components/UI/InfoDialog';
import { useEditFollowUpMutation } from 'services/api/followUpConfig.api';
import type { FollowUpConfigDraft } from 'services/types/followUpConfig';

import FollowUpConfigFormAttributes from './FollowUpConfigFormAttributes';

interface EditFollowUpConfigProps {
  followUpConfig: FollowUpConfigDraft;
  setFollowUpConfig: (followUpConfig: FollowUpConfigDraft) => void;
  followUpConfigId: number;
}

const EditFollowUpConfig: FC<EditFollowUpConfigProps> = ({ followUpConfig, setFollowUpConfig, followUpConfigId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isError, setIsError] = useState(false);

  const [editFollowUpConfig, { isError: isEditError, error: editError, isSuccess: isEditSuccess }] =
    useEditFollowUpMutation();

  useEffect(() => {
    if (isEditError) {
      setIsError(true);
    }
  }, [isEditError]);

  return (
    <Box padding={2} margin={2} border={followUpConfig.isActive ? 'black solid' : 'red solid'} borderRadius={2}>
      <AlertDialog
        openDialog={isError}
        setOpenDialog={setIsError}
        header="Error al editar follow up"
        msg={JSON.stringify(editError)}
        cancelMsg="Cerrar"
      />
      {isEditSuccess && <InfoDialog header="Follow up actualizado!" msg="Follow up fue actualizado correctamente" />}
      <PrimaryBtn onClick={() => setIsEditing(!isEditing)} startIcon={<EditIcon />} variant="contained">
        {!isEditing ? 'Editar' : 'Cancelar'}
      </PrimaryBtn>
      {isEditing && (
        <PrimaryBtn
          onClick={() => {
            editFollowUpConfig({
              ...followUpConfig,
              id: followUpConfigId,
              conditionsToSend: JSON.parse(followUpConfig.conditionsToSend) as string,
              templateComponents: followUpConfig.templateComponents
                ? (JSON.parse(followUpConfig.templateComponents) as string)
                : null,
            }).catch((err) => console.error(err)); // eslint-disable-line no-console
            setIsEditing(false);
          }}
          startIcon={<EditIcon />}
          variant="contained"
        >
          Guardar
        </PrimaryBtn>
      )}

      <FollowUpConfigFormAttributes
        followUpConfigDraft={followUpConfig}
        setFollowUpConfigDraft={setFollowUpConfig}
        isEditing={isEditing}
      />
    </Box>
  );
};

export default EditFollowUpConfig;
