import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AlertDialog from 'components/UI/AlertDialog';
import BusinessUnitSelector from 'components/common/BusinessUnitsSelector';
import { useAdminGetBusinessUnitTemplatesMutation } from 'services/api/businessUnits.api';
import { FollowUpConfigDraft } from 'services/types/followUpConfig';
import { TemplateResponse } from 'services/types/message';

import CreateFollowUpConfig from './CreateFollowUpConfig';
import HandleFile from './handleFile';

const Campaigns = () => {
  const [businessUnitId, setBusinessUnitId] = useState<number | null>(null);
  const [newFollowUpConfig, setNewFollowUpConfig] = useState<FollowUpConfigDraft | null>(null);
  const [outboundCampaignId, setOutboundCampaignId] = useState<string | null>(null);
  const [source, setSource] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponse[number] | null>(null);
  const [isFollowUpCreateSuccess, setIsFollowUpCreateSuccess] = useState(false);
  const [followUpSuccessAlert, setFollowUpSuccessAlert] = useState(false);
  const [clientUploadSuccessAlert, setClientUploadSuccessAlert] = useState(false);

  const [getBusinessUnitTemplates, { data: templates }] = useAdminGetBusinessUnitTemplatesMutation();

  const getTemplateBodyParameters = () => {
    if (!selectedTemplate) return null;
    const bodyComponent = selectedTemplate.components.find((component) => component.type === 'BODY');
    if (bodyComponent && bodyComponent.example && bodyComponent.example.body_text) {
      return JSON.stringify(
        {
          bodyComponents: bodyComponent.example.body_text[0],
        },
        null,
        2
      );
    }
    return null;
  };

  const resetForm = () => {
    setOutboundCampaignId('');
    setSelectedTemplate(null);
    setNewFollowUpConfig(null);
    setIsFollowUpCreateSuccess(false);
  };

  useEffect(() => {
    if (businessUnitId && businessUnitId !== -1) {
      resetForm();
      getBusinessUnitTemplates(businessUnitId).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  }, [businessUnitId]);

  useEffect(() => {
    if (!businessUnitId) return;
    setNewFollowUpConfig({
      businessUnitId,
      templateName: selectedTemplate?.name || null,
      minMinutesWaitTime: 10,
      maxMinutesWaitTime: 60,
      minWorkingHour: 9,
      maxWorkingHour: 18,
      sendOnWeekend: true,
      identifier: outboundCampaignId || '',
      maxConsecutive: 1,
      maxAmountInConversation: 1,
      previousMessageKeepAliveIdentifier: null,
      requiredPreviousFollowUpsIds: null,
      conditionsToSend: JSON.stringify(
        {
          equal: [{ field: 'outboundCampaignId', value: outboundCampaignId }],
        },
        null,
        2
      ),
      templateComponents: getTemplateBodyParameters(),
      isActive: true,
      clientsBatchSize: 1,
      functionName: null,
      prompt: null,
      specificTexts: null,
    });
  }, [businessUnitId, selectedTemplate, outboundCampaignId]);

  if (!businessUnitId || businessUnitId === -1)
    return (
      <Box pl={1} pt={1} display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="h4" pr={1}>
          Selecciona un business unit para crear una campaña:
        </Typography>
        <BusinessUnitSelector
          businessUnitId={businessUnitId}
          setBusinessUnitId={(value: number) => {
            setBusinessUnitId(value);
          }}
        />
      </Box>
    );

  return (
    <Box pl={2} py={2}>
      <AlertDialog
        openDialog={followUpSuccessAlert}
        setOpenDialog={setFollowUpSuccessAlert}
        header="Follow up creado!"
        msg="Follow up fue creado correctamente"
        cancelMsg="Cerrar"
      />
      <AlertDialog
        openDialog={clientUploadSuccessAlert}
        setOpenDialog={setClientUploadSuccessAlert}
        header="Clientes subidos!"
        msg="Clientes fueron subidos correctamente"
        cancelMsg="Cerrar"
      />
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center" pt={1}>
        <Typography variant="h3" pr={1}>
          Campañas:
        </Typography>
        <BusinessUnitSelector
          businessUnitId={businessUnitId}
          setBusinessUnitId={(value: number) => {
            setBusinessUnitId(value);
          }}
        />
      </Box>

      {newFollowUpConfig && !isFollowUpCreateSuccess && (
        <Box width="50%">
          <Typography pb={1} variant="body1">
            Define un identificador para la campaña, se utilizará el mismo para el follow up config:
          </Typography>
          <TextField
            label="OutboundCampaignId"
            value={outboundCampaignId || ''}
            onChange={(e) => setOutboundCampaignId(e.target.value)}
            type="text"
            required
            fullWidth
          />
          <Typography pb={1} variant="body1">
            Define una fuente para los clientes:
          </Typography>
          <TextField
            label="source"
            value={source || ''}
            onChange={(e) => setSource(e.target.value)}
            type="text"
            required
            fullWidth
          />
          <Typography variant="body1" py={1}>
            Asegúrate de que si el template tiene parámetros, que solamente tenga en el body
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="select-template">Seleccionar plantilla</InputLabel>
            <Select
              id="select-template"
              value={selectedTemplate?.name || ''}
              label="Seleccionar plantilla"
              required
              onChange={(e) => {
                const targetTemplate = templates?.find((template) => template.name === e.target.value);
                if (targetTemplate) {
                  setSelectedTemplate(targetTemplate);
                }
              }}
            >
              {templates?.map((template) => (
                <MenuItem key={template.id} value={template.name}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {newFollowUpConfig && !isFollowUpCreateSuccess && (
        <CreateFollowUpConfig
          followUpConfigDraft={newFollowUpConfig}
          setFollowUpConfigDraft={setNewFollowUpConfig}
          onSuccess={() => {
            setFollowUpSuccessAlert(true);
            setIsFollowUpCreateSuccess(true);
          }}
        />
      )}
      {isFollowUpCreateSuccess && outboundCampaignId && source && (
        <HandleFile
          businessUnitId={businessUnitId}
          outboundCampaignId={outboundCampaignId}
          source={source}
          setIsSuccess={() => {
            resetForm();
            setClientUploadSuccessAlert(true);
          }}
        />
      )}
    </Box>
  );
};

export default Campaigns;
