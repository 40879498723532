import React, { FC, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import InfoDialog from 'components/UI/InfoDialog';
import FollowUpConfigFormAttributes from 'pages/FollowUpConfig/FollowUpConfigFormAttributes';
import { useCreateFollowUpMutation } from 'services/api/followUpConfig.api';
import type { FollowUpConfigDraft } from 'services/types/followUpConfig';

interface FollowUpConfigFormProps {
  followUpConfigDraft: FollowUpConfigDraft;
  setFollowUpConfigDraft: (followUpConfigDraft: FollowUpConfigDraft) => void;
  onSuccess: () => void;
}

const FollowUpConfigForm: FC<FollowUpConfigFormProps> = ({
  followUpConfigDraft,
  setFollowUpConfigDraft,
  onSuccess,
}) => {
  const [isError, setIsError] = useState(false);

  const [createFollowUpConfig, { isError: isCreateError, error: createError, isSuccess: isCreateSuccess }] =
    useCreateFollowUpMutation();

  useEffect(() => {
    if (isCreateError) {
      setIsError(true);
    }
  }, [isCreateError]);

  useEffect(() => {
    if (isCreateSuccess) {
      onSuccess();
    }
  }, [isCreateSuccess]);

  return (
    <Box width="50%">
      {/* TODO: Improve alert, it seems like an error */}
      <AlertDialog
        openDialog={isError}
        setOpenDialog={setIsError}
        header={`Error al ${createError ? 'crear' : 'editar'} follow up`}
        msg={JSON.stringify(createError)}
        cancelMsg="Cerrar"
      />
      {isCreateSuccess && <InfoDialog header="Follow up creado!" msg="Follow up fue creado correctamente" />}
      <FollowUpConfigFormAttributes
        followUpConfigDraft={followUpConfigDraft}
        setFollowUpConfigDraft={setFollowUpConfigDraft}
        isEditing
        isCampaign
      />
      <PrimaryBtn
        onClick={() => {
          createFollowUpConfig({
            ...followUpConfigDraft,
            conditionsToSend: JSON.parse(followUpConfigDraft.conditionsToSend) as string,
            templateComponents: followUpConfigDraft.templateComponents
              ? (JSON.parse(followUpConfigDraft.templateComponents) as string)
              : null,
          }).catch((err) => console.error(err)); // eslint-disable-line no-console
        }}
        startIcon={<EditIcon />}
        variant="contained"
      >
        Crear
      </PrimaryBtn>
    </Box>
  );
};

export default FollowUpConfigForm;
