import config from 'config';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCVyjm62BIfuBKuF8c2fIlO5CEKGCPdgsk',
  authDomain: 'saas-relif.firebaseapp.com',
  projectId: 'saas-relif',
  // storageBucket: 'saas-relif.firebasestorage.app',
  messagingSenderId: '816446680429',
  appId: '1:816446680429:web:5b1f462b052b947e54be64',
  measurementId: 'G-RT0YD698DB',
};

const initializeFirebase = () => {
  if (config.environment === 'test') {
    return undefined;
  }
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  return messaging;
};

// eslint-disable-next-line import/prefer-default-export
export const messaging = initializeFirebase();
