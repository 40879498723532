import type { FollowUpConfig, FollowUpConfigDraft } from 'services/types/followUpConfig';

import baseApi from './baseApi';

const followUpConfigApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFollowUps: build.query({
      query: (businessUnitId: number) => ({
        url: `/admin/followUpConfig/businessUnit/${businessUnitId}`,
        method: 'GET',
      }),
      transformResponse: (response: FollowUpConfig[]) => response,
    }),
    getFollowUpCampaigns: build.query({
      query: (businessUnitId: number) => ({
        url: `/admin/followUpConfig/follow-up-campaigns/${businessUnitId}`,
        method: 'GET',
      }),
      transformResponse: (response: FollowUpConfig[]) => response,
    }),
    editFollowUp: build.mutation({
      query: (followUpConfig: FollowUpConfigDraft & { id: number }) => ({
        url: `admin/followUpConfig/${followUpConfig.id}`,
        method: 'PATCH',
        body: followUpConfig,
      }),
      transformResponse: (response: FollowUpConfig) => response,
    }),
    createFollowUp: build.mutation({
      query: (followUpConfig: FollowUpConfigDraft) => ({
        url: `admin/followUpConfig`,
        method: 'POST',
        body: followUpConfig,
      }),
      transformResponse: (response: FollowUpConfig) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFollowUpsQuery,
  useEditFollowUpMutation,
  useGetFollowUpCampaignsQuery,
  useCreateFollowUpMutation,
} = followUpConfigApi;
