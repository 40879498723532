import React, { FC, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

interface MapHeadersProps {
  rows: { headerName: string; examples: string[] }[];
  mappedHeaders: { headerName: string; attrCliente: string }[];
  setMappedHeaders: React.Dispatch<
    React.SetStateAction<
      {
        headerName: string;
        attrCliente: string;
      }[]
    >
  >;
}

const MapHeaders: FC<MapHeadersProps> = ({ rows, mappedHeaders, setMappedHeaders }) => {
  const allAttributes = {
    firstName: 'Nombre',
    lastName: 'Apellido',
    rut: 'Rut',
    email: 'Email',
    phone: 'Teléfono',
    brandOfInterest: 'Marca de interés',
    model: 'Modelo',
    lastServiceDate: 'Última fecha de servicio',
    branchOffice: 'Sucursal',
    externalId: 'ID externo',
  };
  const [hiddenAttributes, setHiddenAttributes] = useState<string[]>([]);

  useEffect(() => {
    setHiddenAttributes(mappedHeaders.map((h) => h.attrCliente));
  }, [mappedHeaders]);

  return (
    <Box>
      <Typography variant="h6">Mapear encabezados</Typography>
      <Box display="flex" width="90%" justifyContent="space-between">
        <Typography variant="h6">Encabezado</Typography>
        <Typography variant="h6">Data</Typography>
        <Typography variant="h6">Attr Cliente</Typography>
      </Box>
      {rows.map((row) => (
        <Box
          display="flex"
          width="90%"
          justifyContent="space-between"
          border="1px solid #e0e0e0"
          borderRadius={1}
          p={2}
          mb={2}
          key={row.headerName}
        >
          <Box alignItems="center" display="flex">
            <Typography>{row.headerName}</Typography>
          </Box>
          <Box>
            {row.examples.map((example) => (
              <Typography key={`${row.headerName}-${example}-${Math.random()}`}>{example}</Typography>
            ))}
          </Box>
          <Box minWidth={200}>
            <FormControl fullWidth>
              <InputLabel id="select-attr-cliente">Seleccionar Attr</InputLabel>
              <Select
                id="select-attr-cliente"
                value={mappedHeaders.find((header) => header.headerName === row.headerName)?.attrCliente || ''}
                label="Seleccionar Attr Cliente"
                onChange={(e) => {
                  const headerExists = mappedHeaders.some((header) => header.headerName === row.headerName);
                  if (headerExists) {
                    setMappedHeaders((prev) =>
                      prev.map((header) =>
                        header.headerName === row.headerName ? { ...header, attrCliente: e.target.value } : header
                      )
                    );
                  } else {
                    setMappedHeaders((prev) => [...prev, { headerName: row.headerName, attrCliente: e.target.value }]);
                  }
                }}
              >
                {Object.entries(allAttributes).map(([key, value]) => (
                  <MenuItem
                    value={key}
                    key={`${row.headerName}-${key}-${Math.random()}`}
                    disabled={hiddenAttributes.includes(key)}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default MapHeaders;
