import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useCurrentUser from 'hooks/useCurrentUser';

import ChangePassword from './ChangePassword';
import NotificationsPermissions from './NotificationsPermissions';

const Profile = () => {
  const currentUser = useCurrentUser();

  return (
    <Box sx={{ p: { xs: 2 } }}>
      <Typography variant="h4" mt={2} sx={{ ml: { xs: 6, sm: 10, md: 0 } }}>
        Mi Perfil: {currentUser?.name}
      </Typography>
      <ChangePassword />
      <NotificationsPermissions />
    </Box>
  );
};

export default Profile;
