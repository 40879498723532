import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { getToken } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import { useHandleFirebaseNotificationTokensMutation } from 'services/api/user.api';

const Notifications: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<'granted' | 'denied' | 'default'>('default');

  const [handleFirebaseNotificationTokens] = useHandleFirebaseNotificationTokensMutation();

  async function requestPermission() {
    if (!messaging) {
      // eslint-disable-next-line no-console
      console.error('Messaging is not initialized');
      return;
    }
    const newPermission = await Notification.requestPermission();
    setPermission(newPermission);

    if (newPermission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BJKa7jjFgmbQlKkEAgd39ptf8rH8uNxrmfwJxEnyZLehh6Xgxuqqo2vUDi0Q8KncUsGsfIJ_A6EYyVmkbBaknxs',
      });
      await handleFirebaseNotificationTokens({ newNotificationToken: token });
    } else if (newPermission === 'denied') {
      // eslint-disable-next-line no-console
      console.log('You denied the notifications');
    } else if (newPermission === 'default') {
      // eslint-disable-next-line no-console
      console.log('You did not respond to the notifications');
    }
  }

  if (!messaging) {
    // eslint-disable-next-line no-console
    console.error('Messaging is not initialized');
    return <div>Messaging is not initialized</div>;
  }

  const handleRequestPermission = () => {
    setIsLoading(true);
    requestPermission()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('An error occurred while retrieving the token.', err);
      });
  };

  return (
    <Box>
      <Typography variant="h4">Notificaciones</Typography>
      {isLoading && <CircularProgress />}
      {!isLoading && permission === 'default' && (
        <>
          <Typography variant="body1">Solicitar permiso para recibir notificaciones de la aplicación</Typography>
          <Button variant="contained" onClick={handleRequestPermission} disabled={isLoading}>
            Habilitar notificaciones
          </Button>
        </>
      )}
      {!isLoading && permission === 'granted' && <Typography variant="body1">Notificaciones activadas</Typography>}
      {!isLoading && permission === 'denied' && (
        <Typography variant="body1">
          Notificaciones desactivadas, tienes que activar las notificaciones en tu navegador
        </Typography>
      )}
    </Box>
  );
};

export default Notifications;
