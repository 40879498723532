import {
  BusinessUnitClientsMetrics,
  BusinessUnitMessagesMetrics,
  FinancialAdvisorMetrics,
} from 'services/types/metrics';

export const getBigHardMetrics = ({
  businessUnitType,
  businessUnitClientsMetrics,
  businessUnitMessagesMetrics,
  businessUnitFinancialAdvisorMetrics,
}: {
  businessUnitType: string;
  businessUnitClientsMetrics: BusinessUnitClientsMetrics | null;
  businessUnitMessagesMetrics: BusinessUnitMessagesMetrics | null;
  businessUnitFinancialAdvisorMetrics: FinancialAdvisorMetrics | null;
}) => {
  if (!businessUnitClientsMetrics || !businessUnitMessagesMetrics) {
    return [];
  }
  if (businessUnitType === 'car_dealership') {
    return [
      {
        type: 'Cantidad de clientes',
        data: businessUnitClientsMetrics.hardMetrics.clientsCount,
        tooltip: 'Cantidad de clientes creados en el rango de fechas seleccionado',
      },
      {
        type: 'Clientes en CRM',
        data:
          (businessUnitClientsMetrics.hardMetrics.clientsUploadedToCRM || 0) /
          (businessUnitClientsMetrics.hardMetrics.clientsCount || 1),
        secondaryData: businessUnitClientsMetrics.hardMetrics.clientsUploadedToCRM,
        tooltip: 'Clientes que se enviaron al CRM de la empresa (de los creados en el rango de fechas seleccionado)',
        percentage: true,
      },
      {
        type: 'Clientes que agendan visita',
        data:
          (businessUnitClientsMetrics.hardMetrics.clientsWithVisitDate || 0) /
            (businessUnitClientsMetrics.hardMetrics.clientsCount || 1) || 0,
        secondaryData: businessUnitClientsMetrics.hardMetrics.clientsWithVisitDate,
        tooltip: 'Clientes que agendaron visita (de los creados en el rango de fechas seleccionado)',
        percentage: true,
      },
      {
        type: 'Clientes contactados',
        data:
          (businessUnitClientsMetrics.hardMetrics.clientsContactedCount || 0) /
          (businessUnitClientsMetrics.hardMetrics.clientsCount || 1),
        secondaryData: businessUnitClientsMetrics.hardMetrics.clientsContactedCount,
        tooltip: 'Cantidad de clientes que pudieron ser contactados',
        percentage: true,
      },
    ];
  }
  if (businessUnitType === 'financial_advisor') {
    if (!businessUnitFinancialAdvisorMetrics) {
      return [];
    }
    const totalClientsCount = businessUnitClientsMetrics.hardMetrics.clientsCount;

    const clientsWithOnboardingCompleteCount =
      businessUnitFinancialAdvisorMetrics.userMetrics.usersWithCMFRequest.length;
    const clientsWithOffersCount =
      businessUnitFinancialAdvisorMetrics.bankOfferMetrics.refinanceOffersMetrics.bankOfferUsers.length;
    const offersWithInterestCount =
      businessUnitFinancialAdvisorMetrics.bankOfferMetrics.refinanceOffersMetrics.bankOffers.filter(
        (offer) => offer.userReaction?.includes('interesado')
      ).length;

    return [
      {
        type: 'Clientes',
        data: totalClientsCount,
        tooltip: 'Cantidad de clientes creados en el rango de fechas seleccionado',
      },
      {
        type: 'Clientes con onboarding completo',
        data: clientsWithOnboardingCompleteCount / totalClientsCount,
        secondaryData: clientsWithOnboardingCompleteCount,
        tooltip: 'Cantidad de clientes con onboarding completo',
        percentage: true,
      },

      {
        type: 'Clientes con oferta',
        data: clientsWithOffersCount / clientsWithOnboardingCompleteCount,
        secondaryData: clientsWithOffersCount,
        tooltip:
          'Cantidad de clientes con ofertas de refinanciamiento (medido sobre el total de clientes con onboarding completo)',
        percentage: true,
      },
      {
        type: 'Clientes con interés en oferta',
        data: offersWithInterestCount / clientsWithOffersCount,
        secondaryData: offersWithInterestCount,
        tooltip:
          'Cantidad de clientes con interés en las ofertas de refinanciamiento (medido sobre el total de clientes con oferta)',
        percentage: true,
      },
    ];
  }
  return [];
};

export const getSmallHardMetrics = ({
  businessUnitType,
  businessUnitClientsMetrics,
  businessUnitMessagesMetrics,
  businessUnitFinancialAdvisorMetrics,
}: {
  businessUnitType: string;
  businessUnitClientsMetrics: BusinessUnitClientsMetrics | null;
  businessUnitMessagesMetrics: BusinessUnitMessagesMetrics | null;
  businessUnitFinancialAdvisorMetrics: FinancialAdvisorMetrics | null;
}) => {
  if (!businessUnitClientsMetrics || !businessUnitMessagesMetrics) {
    return [];
  }
  if (businessUnitType === 'car_dealership') {
    return [
      {
        type: 'Clientes fallidos',
        data:
          (businessUnitClientsMetrics.hardMetrics.clientsThatFailedToBeContacted || 0) /
          (businessUnitClientsMetrics.hardMetrics.clientsCount || 1),
        secondaryData: businessUnitClientsMetrics.hardMetrics.clientsThatFailedToBeContacted,
        tooltip: 'Clientes que no pudieron ser contactados. Probablemente tienen un teléfono incorrecto',
        percentage: true,
      },
      {
        type: 'Clientes por contactar',
        data:
          (businessUnitClientsMetrics.hardMetrics.clientsToBeContacted || 0) /
          (businessUnitClientsMetrics.hardMetrics.clientsCount || 1),
        secondaryData: businessUnitClientsMetrics.hardMetrics.clientsToBeContacted,
        tooltip:
          'Clientes de campañas de outbound que no han sido contactados . No se incluyen dentro de las otras métricas',
        percentage: false,
      },
      {
        type: 'Clientes que respondieron',
        data:
          (businessUnitClientsMetrics.hardMetrics.clientsThatAnsweredCount || 0) /
          (businessUnitClientsMetrics.hardMetrics.clientsCount || 1),
        secondaryData: businessUnitClientsMetrics.hardMetrics.clientsThatAnsweredCount,
        tooltip:
          'Clientes que enviaron algún mensaje. Toma en cuenta los creados dentro del rango de fechas seleccionado',
        percentage: true,
      },
      {
        type: 'Mensajes por cliente',
        data: businessUnitClientsMetrics.hardMetrics.averageMessagesPerClient,
        tooltip:
          'Promedio de mensajes en las conversaciones. Considera solo los clientes creados en ese rango de fechas',
      },
      {
        type: 'Cantidad de mensajes',
        data: businessUnitMessagesMetrics.hardMetrics.messagesCount,
        tooltip: 'Cantidad de mensajes en el rango de fechas seleccionado',
      },
      {
        type: 'Mensajes de clientes',
        data: businessUnitMessagesMetrics.hardMetrics.messagesFromUserCount,
        tooltip: 'Cantidad de mensajes enviados por clientes en el rango de fechas seleccionado',
      },
      {
        type: 'Fuera de horario laboral',
        data:
          (businessUnitMessagesMetrics.hardMetrics.totalMessagesOutOfOfficeHours || 0) /
          (businessUnitMessagesMetrics.hardMetrics.messagesCount || 1),
        secondaryData: businessUnitMessagesMetrics.hardMetrics.totalMessagesOutOfOfficeHours,
        tooltip:
          'Mensajes enviados fuera del horario laboral (lunes a viernes de 9 a 18) en el rango de fechas seleccionado',
        percentage: true,
      },
      {
        type: 'Fotos/documentos',
        data: businessUnitMessagesMetrics.hardMetrics.messagesWithFileCount,
        tooltip: 'Cantidad de fotos y documentos enviados a clientes en el rango de fechas seleccionado',
      },
    ];
  }
  if (businessUnitType === 'financial_advisor') {
    if (!businessUnitFinancialAdvisorMetrics) {
      return [];
    }
    const clientsWithOnboardingCompleteCount =
      businessUnitFinancialAdvisorMetrics.userMetrics.usersWithCMFRequest.length;
    const upToDateClientsCount = businessUnitFinancialAdvisorMetrics.userMetrics.usersWithOnDueDebts.length;
    const clientsWithUnpaidDebtsCount = clientsWithOnboardingCompleteCount - upToDateClientsCount;
    return [
      {
        type: 'Clientes al día',
        data: upToDateClientsCount / clientsWithOnboardingCompleteCount,
        secondaryData: upToDateClientsCount,
        tooltip: 'Cantidad de clientes con deudas al día (medido sobre los clientes que completaron onboarding)',
        percentage: true,
      },
      {
        type: 'Clientes morosos',
        data: clientsWithUnpaidDebtsCount / clientsWithOnboardingCompleteCount,
        secondaryData: clientsWithUnpaidDebtsCount,
        tooltip: 'Cantidad de clientes con deudas morosas (medido sobre los clientes que completaron onboarding)',
        percentage: true,
      },
      {
        type: 'Mensajes por cliente',
        data: businessUnitClientsMetrics.hardMetrics.averageMessagesPerClient,
        tooltip:
          'Promedio de mensajes en las conversaciones. Considera solo los clientes creados en ese rango de fechas',
      },
      {
        type: 'Cantidad de mensajes',
        data: businessUnitMessagesMetrics.hardMetrics.messagesCount,
        tooltip: 'Cantidad de mensajes en el rango de fechas seleccionado',
      },
      {
        type: 'Mensajes de clientes',
        data: businessUnitMessagesMetrics.hardMetrics.messagesFromUserCount,
        tooltip: 'Cantidad de mensajes enviados por clientes en el rango de fechas seleccionado',
      },
    ];
  }
  return [];
};
