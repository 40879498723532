import React, { useState, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useGetFollowUpsQuery } from 'services/api/followUpConfig.api';
import type { FollowUpConfig, FollowUpConfigDraft } from 'services/types/followUpConfig';

import EditFollowUpConfig from './EditFollowUpConfig';

interface FollowUpConfigWithDraftsProps {
  followUpConfig: FollowUpConfig;
}

const FollowUpConfigWithDrafts: FC<FollowUpConfigWithDraftsProps> = ({ followUpConfig }) => {
  const [followUpConfigDraft, setFollowUpConfigDraft] = useState<FollowUpConfigDraft>({
    ...followUpConfig,
    conditionsToSend: JSON.stringify(followUpConfig.conditionsToSend),
    templateComponents: JSON.stringify(followUpConfig.templateComponents),
  });
  return (
    <EditFollowUpConfig
      followUpConfig={followUpConfigDraft}
      setFollowUpConfig={setFollowUpConfigDraft}
      followUpConfigId={followUpConfig.id}
    />
  );
};

const FollowUpConfigEdit = () => {
  const { businessUnitId } = useParams();
  const navigate = useNavigate();

  if (!businessUnitId) {
    return <div>BusinessUnit id not found</div>;
  }
  const followUps = useGetFollowUpsQuery(Number(businessUnitId));

  return (
    <Box pl={1}>
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center">
        <Typography variant="h3">Follow Ups</Typography>
        <PrimaryBtn sx={{ ml: 2 }} onClick={() => navigate(-1)} variant="contained">
          Volver
        </PrimaryBtn>
      </Box>

      {followUps.data?.map((followUp) => <FollowUpConfigWithDrafts key={followUp.id} followUpConfig={followUp} />)}
    </Box>
  );
};

export default FollowUpConfigEdit;
