import React, { useEffect, useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useAdminUploadClientsFromCSVMutation } from 'services/api/clients.api';

import MapHeaders from './mapHeaders';

interface HandleFileProps {
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  businessUnitId: number;
  outboundCampaignId: string;
  source: string;
}

const HandleFile = ({ setIsSuccess, businessUnitId, outboundCampaignId, source }: HandleFileProps) => {
  const [fileToSend, setFileToSend] = useState<File | null>(null);
  const [selectedSeparator, setSelectedSeparator] = useState<string>(';');
  const [headers, setHeaders] = useState<string[]>([]);
  const [exampleData, setExampleData] = useState<string[][]>([]);
  const [mappedHeaders, setMappedHeaders] = useState<{ headerName: string; attrCliente: string }[]>([]);

  const [uploadClientsFromCSV] = useAdminUploadClientsFromCSVMutation();

  const hasPhoneAttr = mappedHeaders.find((h) => h.attrCliente === 'phone');

  const parseFile = async (file: File) => {
    const text = await file.text();
    const rows = text.split('\n');
    setHeaders(rows[0].split(selectedSeparator));
    setExampleData(rows.slice(1, 6).map((row) => row.split(selectedSeparator)));
  };

  useEffect(() => {
    if (fileToSend && selectedSeparator) {
      parseFile(fileToSend).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    }
  }, [fileToSend, selectedSeparator]);

  return (
    <Box pl={2} py={2}>
      <Typography variant="h6" py={1}>
        Subir archivo csv con clientes:
      </Typography>
      <Box display="flex">
        <Box minWidth={150}>
          <FormControl fullWidth>
            <InputLabel id="select-separator">Seleccionar separador</InputLabel>
            <Select
              id="select-separator"
              value={selectedSeparator}
              label="Seleccionar separador"
              onChange={(e) => setSelectedSeparator(e.target.value)}
            >
              <MenuItem value=";">
                <b>;</b>
              </MenuItem>
              <MenuItem value=",">
                <b>,</b>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <label htmlFor="file-input">
          <input
            id="file-input"
            name="file-input"
            type="file"
            style={{ display: 'none' }}
            accept=".csv"
            onChange={(e) => {
              if (e.target.files) {
                setFileToSend(e.target.files[0]);
              }
            }}
          />
          <Button variant="text" component="span">
            <AttachFileIcon fontSize="large" />
          </Button>
        </label>
        {fileToSend && <Typography variant="body2">{fileToSend.name}</Typography>}
      </Box>
      {headers && exampleData && fileToSend && (
        <Box>
          <Typography variant="h6">Primeras 5 filas de {fileToSend.name}</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {exampleData.map((row) => (
                  <TableRow key={row.join('')}>
                    {row.map((cell) => (
                      <TableCell key={`${cell}-${Math.random()}`}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {headers.length > 0 && exampleData.length > 0 && (
        <MapHeaders
          rows={headers.map((header, index) => ({
            headerName: header,
            examples: exampleData.map((row) => row[index]),
          }))}
          mappedHeaders={mappedHeaders}
          setMappedHeaders={setMappedHeaders}
        />
      )}

      {!hasPhoneAttr && fileToSend && (
        <Typography variant="body1">No se ha seleccionado el atributo de teléfono</Typography>
      )}
      {fileToSend && (
        <PrimaryBtn
          onClick={() => {
            if (!hasPhoneAttr) return;
            uploadClientsFromCSV({
              businessUnitId,
              outboundCampaignId,
              source,
              mappedHeaders,
              separator: selectedSeparator,
              file: fileToSend,
            }).catch((error) => {
              // eslint-disable-next-line no-console
              console.error(error);
            });
            setIsSuccess(true);
          }}
          disabled={!hasPhoneAttr}
        >
          Subir archivo
        </PrimaryBtn>
      )}
    </Box>
  );
};

export default HandleFile;
