import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { BackIcon } from 'assets/icons';
import ErrorImg from 'assets/images/404.webp';
import { PrimaryBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <SectionHeader text="Error 404" button={null} />
      <PaperContainer title="Página no encontrada">
        <Typography variant="h5" mb={2}>
          Oops... La página que buscas no existe
        </Typography>
        <img src={ErrorImg} alt="Error" style={{ width: 'min(600px, 98%)' }} />
        <PrimaryBtn
          onClick={() => navigate('/clients')}
          sx={{ mt: 6, width: '200px' }}
          startIcon={<BackIcon />}
          variant="contained"
        >
          Volver al Inicio
        </PrimaryBtn>
      </PaperContainer>
    </>
  );
};

export default ErrorPage;
