import React, { FC, useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import useCurrentUser from 'hooks/useCurrentUser';
import { useAssignClientToUserMutation, useGetAssignableUsersMutation } from 'services/api/user.api';
import { Client } from 'services/types/client';

interface Props {
  client: Client;
}

const UserAssigner: FC<Props> = ({ client }) => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(client.userId || null);

  const currentUser = useCurrentUser();
  const [getAssignableUsers, { data: assignableUsers }] = useGetAssignableUsersMutation();
  const [assignClientToUser, { isError: isErrorAssignClientToUser }] = useAssignClientToUserMutation();

  useEffect(() => {
    if (currentUser?.canAssignClient) {
      getAssignableUsers(client.id).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    }
  }, [client.id]);

  useEffect(() => {
    if (selectedUserId && selectedUserId !== client.userId) {
      assignClientToUser({ userId: selectedUserId, clientToAssignId: client.id }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    }
  }, [selectedUserId]);

  if (client.user && !currentUser?.canReassignClient) {
    return (
      <Typography>
        <b>Asignar a:</b>
        <br />
        El cliente ya está asignado a <b>{client.user?.name}</b> y no puedes reasignarlo
      </Typography>
    );
  }
  if (!assignableUsers?.assignableUsers) {
    return (
      <Typography>
        <b>Asignar a:</b> No hay usuarios disponibles para asignar
      </Typography>
    );
  }

  if (isErrorAssignClientToUser) {
    return (
      <>
        <Typography fontWeight={700}>Asignar a:</Typography>
        <Alert severity="error">Error al asignar el cliente al usuario</Alert>
      </>
    );
  }

  return (
    <>
      <Typography fontWeight={700} pb={1}>
        Asignar a:
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="user-select-label">Usuario</InputLabel>
        <Select
          label="Usuario"
          variant="outlined"
          onChange={(e) => setSelectedUserId(Number(e.target.value))}
          value={selectedUserId || ''}
          autoWidth
        >
          {assignableUsers.assignableUsers?.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              <Typography fontWeight={500}>{user.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default UserAssigner;
