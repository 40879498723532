import React, { FC, useEffect, useRef, useState } from 'react';

import CampaignIcon from '@mui/icons-material/Campaign';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Launch from '@mui/icons-material/Launch';
import PollIcon from '@mui/icons-material/Poll';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CheckIcon, ClockIcon } from 'assets/icons';
import config from 'config';
import useCurrentUser from 'hooks/useCurrentUser';
import { Message } from 'services/types/message';
import textToReactNodes from 'utils/textToReactNodes';

import EvalModal from '../../../pages/Evals/EvalModal';

const filterOutStatuses = ['neverSent', 'deleted', 'discarded', 'failed'];
// TODO: change this to use new virtual field: fileUrl
const BUCKET_NAME = config.environment === 'development' ? 'saas-test-bucket' : 'saasbucket';
const BUCKET_URL = `https://storage.googleapis.com/${BUCKET_NAME}`;

const getExtension = (fileName: string): string => {
  // file.png -> png, file.ogg; codecs=opus -> ogg
  return fileName.split(';')[0].split('.').at(-1) as string;
};

interface Props {
  conversation: Message[];
  isFilteredConversation: boolean;
  fullHeight?: boolean;
  isClientView?: boolean;
  isAssistantWriting?: boolean;
}

const Conversation: FC<Props> = ({
  conversation,
  isFilteredConversation,
  fullHeight,
  isClientView,
  isAssistantWriting,
}) => {
  const filteredConversation = isFilteredConversation
    ? conversation.filter((message) => !filterOutStatuses.includes(message.status))
    : conversation;
  const [openEvalModal, setOpenEvalModal] = useState(false);
  const [evalMessage, setEvalMessage] = useState<null | Message>(null);
  const currentUser = useCurrentUser();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [conversation]);

  const getMessageButtons = (buttons: Message['buttons']) =>
    buttons && (
      <Stack pb={1} spacing={1}>
        {buttons.map((button) => (
          <div key={`${button.id} - ${button.title}`}>
            <Divider />
            <Box textAlign="center" alignSelf="center">
              {button.url ? (
                <Button
                  variant="text"
                  startIcon={<Launch fontSize="small" />}
                  href={button.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {button.title}
                </Button>
              ) : (
                button.title
              )}
            </Box>
          </div>
        ))}
      </Stack>
    );

  const getMessageHTML = (message: Message) => {
    let text = '';
    if (message.role === 'assistant' && message.functionName && message.functionArguments) {
      text += `Asistente: ${message.functionName}(${message.functionArguments?.replaceAll('\n', '')})`;
    } else if (message.role === 'function') {
      text += `Función: ${message.text?.replaceAll('\\', '').replace(/(^")|("$)/g, '')}`;
    } else {
      text += message.text ?? '';
    }
    text += '\n';

    const isSpecialStatus = [
      'failed',
      'deleted',
      'discarded',
      'warning',
      'functionSuggestion',
      'functionResponse',
      'suggestion',
      'neverSent',
    ].includes(message.status);

    return (
      <>
        {['system', 'none'].includes(message.sender) && currentUser?.isAdmin && (
          <div
            style={{
              position: 'relative',
              float: 'right',
              top: '0px',
            }}
          >
            <IconButton
              color="inherit"
              size="small"
              title="Evaluar este mensaje"
              onClick={() => {
                setOpenEvalModal(true);
                setEvalMessage(message);
              }}
            >
              <PollIcon fontSize="inherit" />
            </IconButton>
            <a
              href={`/playground/${message.id}`}
              style={{ textDecoration: 'none', margin: 0, padding: 0 }}
              target="_blank"
              rel="noreferrer"
              title="Replicar este mensaje en el playground"
            >
              &gt;
            </a>
          </div>
        )}

        {Number(message.sender) > 0 && currentUser?.isAdmin && (
          <div
            style={{
              position: 'relative',
              float: 'right',
              top: '0px',
            }}
            title="Mensaje enviado por user con este id"
          >
            ({message.sender})
          </div>
        )}
        {message.fileName && ['ogg'].includes(getExtension(message.fileName)) && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio
            controls
            src={message.fileName.startsWith('http') ? message.fileName : `${BUCKET_URL}/${message.fileName}`}
          />
        )}
        {text && (!message.fileName || getExtension(message.fileName) === 'ogg') && (
          <Typography
            variant="body1"
            px={1}
            sx={{
              whiteSpace: 'pre-line',
              textAlign: 'left',
              wordBreak: 'break-word',
              color: isSpecialStatus ? 'grey' : undefined,
            }}
          >
            {textToReactNodes(text)}
          </Typography>
        )}
        {message.url && (
          <a href={message.url} target="_blank" rel="noreferrer">
            <Typography variant="body2" px={1}>
              {message.fileName}
            </Typography>
          </a>
        )}
        {message.fileName && ['png', 'jpeg', 'jpg', 'webp', 'mp4'].includes(getExtension(message.fileName)) && (
          <>
            {getExtension(message.fileName) === 'mp4' && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video width="100%" autoPlay muted controls>
                <source
                  src={message.fileName.startsWith('http') ? message.fileName : `${BUCKET_URL}/${message.fileName}`}
                  type="video/mp4"
                />
              </video>
            )}
            {getExtension(message.fileName) !== 'mp4' && (
              <img
                src={message.fileName.startsWith('http') ? message.fileName : `${BUCKET_URL}/${message.fileName}`}
                alt="chat-file"
                style={{ maxWidth: '100%', maxHeight: message.fileName.includes('webp') ? '120px' : '250px' }}
              />
            )}
            <br />
            {message.fileName.includes('referral') && <CampaignIcon color="primary" />}
            {text && (
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: 'pre-line',
                  textAlign: 'left',
                  wordBreak: 'break-word',
                  color: isSpecialStatus ? 'grey' : undefined,
                }}
              >
                {textToReactNodes(
                  text
                    .replace(/Imagen enviada: \S+\.(png|jpeg|jpg|webp)/, '')
                    .replace('Imagen enviada', '')
                    .trim()
                )}
              </Typography>
            )}
          </>
        )}
        {message.fileName && !['png', 'jpeg', 'jpg', 'webp', 'mp4', 'ogg'].includes(getExtension(message.fileName)) && (
          <Box
            p={1}
            sx={{
              backgroundColor: message.role === 'user' ? '#f3f5f5' : '#d8f3ce',
              borderRadius: '10px',
            }}
          >
            <Button
              href={
                message.fileName.startsWith('http')
                  ? message.fileName
                  : `https://storage.googleapis.com/saasbucket/${message.fileName}`
              }
              target="_blank"
              rel="noreferrer"
              variant="text"
              color="inherit"
              sx={{ textTransform: 'none', width: '100%', display: 'block' }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <InsertDriveFileIcon
                    sx={{
                      height: '1.8em',
                      width: '1.8em',
                    }}
                  />
                  <Stack pr={10} pl={1} textAlign="left">
                    <Typography variant="body1" sx={{ color: '#111b21' }}>
                      {message.text
                        ? message.text
                            .split(':')
                            .pop()
                            ?.replace(/[a-z0-9]*-/g, '')
                        : message.fileName.split('/').pop()}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#111b21' }} fontSize={15}>
                      {getExtension(message.fileName).toLocaleUpperCase()}
                    </Typography>
                  </Stack>
                </Box>
                <DownloadIcon
                  sx={{
                    border: 'solid',
                    borderRadius: '100%',
                    borderWidth: 'thin',
                    opacity: '80%',
                    padding: '2px',
                    height: '1.6em',
                    width: '1.6em',
                  }}
                />
              </Box>
            </Button>
          </Box>
        )}
        <Box display="inline-flex" alignItems="center">
          <Typography variant="body2" p={1} sx={{ color: 'grey' }}>
            {new Date(message.createdAt).toLocaleTimeString('es-CL', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
          </Typography>
          {message.status === 'sent' && <CheckIcon />}
          {(message.status === 'delivered' || message.status === 'received') && (
            <>
              <CheckIcon sx={{ marginRight: '-16px' }} />
              <CheckIcon />
            </>
          )}
          {message.status === 'read' && (
            <>
              <CheckIcon color="info" sx={{ marginRight: '-16px' }} />
              <CheckIcon color="info" />
            </>
          )}
          {message.status === 'sent to api' && currentUser && <ClockIcon />}
          {isSpecialStatus && <Typography variant="body2">{message.status}</Typography>}
          {currentUser?.isAdmin && !isFilteredConversation && message.cost > 0 && (
            <Typography variant="body2" p={1} sx={{ color: 'grey', fontSize: '0.7em' }}>
              ${message.cost.toFixed(2)} {message.AIModel}
            </Typography>
          )}
          {message.emoji && (
            <div
              style={{
                height: '26px',
                width: '30px',
                position: 'relative',
                float: 'right',
                bottom: '-20px',
                backgroundColor: 'white',
                borderRadius: '50px',
                textAlign: 'center',
                border: '1px solid rgba(0,0,0,0.05)',
              }}
            >
              {message.emoji}
            </div>
          )}
        </Box>
        {getMessageButtons(message.buttons)}
        {currentUser?.isAdmin && message.error && (
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '12px',
              textAlign: 'center',
            }}
          >
            {message.error}
          </div>
        )}
      </>
    );
  };

  return (
    <Box height={fullHeight ? '100%' : undefined} display="flex" flexDirection="column">
      {filteredConversation?.map((message) => (
        <Box
          key={message.id}
          m={1}
          p={1}
          pb={0}
          sx={{
            maxWidth: '70%',
            borderRadius: '12px',
            boxShadow: '0 1px 0.5px rgba(11,20,26,.13)',
            ...((!isClientView && message.role !== 'user') || (message.role === 'user' && isClientView)
              ? {
                  backgroundColor: '#d9fdd2',
                  textAlign: 'end',
                  alignSelf: 'flex-end',
                }
              : {
                  backgroundColor: '#fff',
                  textAlign: 'start',
                  alignSelf: 'flex-start',
                }),
          }}
        >
          {getMessageHTML(message)}
        </Box>
      ))}
      {isAssistantWriting && (
        <Box
          m={1}
          p={1}
          pb={0}
          sx={{ maxWidth: '70%', borderRadius: '12px', backgroundColor: '#f3f5f5', textAlign: 'start' }}
        >
          <Typography variant="body1" px={1}>
            Escribiendo...
          </Typography>
        </Box>
      )}
      {openEvalModal && evalMessage && (
        <EvalModal setOpen={setOpenEvalModal} messageText={evalMessage.text || ''} messageId={evalMessage.id} />
      )}
      <div ref={messagesEndRef} />
    </Box>
  );
};

Conversation.defaultProps = {
  fullHeight: false,
  isClientView: false,
  isAssistantWriting: false,
};

export default Conversation;
