import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useRollbarPerson } from '@rollbar/react';
import useCurrentUser from 'hooks/useCurrentUser';

import { checkIfTokenIsValid } from '../authStorage';
import routes, { Route as RouteType } from './routes';

interface Props {
  route: RouteType;
}

const AuthRoute: FC<Props> = ({ route }) => {
  const { component: Component, path, private: privateRoute } = route;
  const user = useCurrentUser();
  const isAdmin = !!user?.isAdmin;
  const isBusinessUnitAdmin = isAdmin || (!!user?.canAssignClient && !user?.isAssignable);
  const isBranchManager = isBusinessUnitAdmin || (!!user?.canAssignClient && !!user?.isAssignable);
  const isSeller = !isBranchManager && !isBusinessUnitAdmin && !isAdmin;

  if (user) {
    useRollbarPerson({
      id: user.id,
      email: user.email,
    });
  }
  const getValidRedirectUrl = (redirectUrl: string): string => {
    // the redirectUrl could be something like: /redirect-path&param=true for this url to work and keep the params
    // the first & needs to be replaced with ?
    const redirectUrlPath = redirectUrl.split('?')[0].split('&')[0];
    const validRedirectUrl = Object.values(routes).some(
      (routeValue) => routeValue.path === redirectUrlPath || routeValue.regex?.test(redirectUrlPath)
    );
    const validUrl = validRedirectUrl ? redirectUrl.replace('&', '?') : '/';
    if (!validUrl || validUrl === '/') {
      return '/clients';
    }
    return validUrl;
  };
  const userIsValid = checkIfTokenIsValid(user);
  if (userIsValid && path === '/sign-in') {
    // Adds the text after '?redirect=/'
    const redirectUrl = `/${window.location.search.slice(11)}`;
    return <Navigate to={getValidRedirectUrl(redirectUrl)} />;
  }
  if (privateRoute && !userIsValid) {
    const signInPath = `/sign-in?redirect=${window.location.pathname}${window.location.search}`;
    return <Navigate to={signInPath} />;
  }
  if (privateRoute && userIsValid && route.role) {
    if (isAdmin) {
      return <Component />;
    }
    if (isBusinessUnitAdmin && route.role === 'admin') {
      return <Navigate to="/unauthorized" />;
    }
    if (isBranchManager && (route.role === 'admin' || route.role === 'businessUnitAdmin')) {
      return <Navigate to="/unauthorized" />;
    }
    if (isSeller && (route.role === 'admin' || route.role === 'businessUnitAdmin' || route.role === 'branchManager')) {
      return <Navigate to="/unauthorized" />;
    }
    return <Component />;
  }
  return <Component />;
};

export default AuthRoute;
