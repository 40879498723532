import React, { FC } from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';

import Sidebar, { drawerWidth } from 'components/layout/SideBar/Sidebar';
import useCurrentRoute from 'hooks/useCurrentRoute';

import styles from './Layout.module.scss';
import DefaultTheme from './themes/DefaultTheme';

const THEMES: Theme[] = [DefaultTheme];

interface Props {
  children?: JSX.Element;
}

const Layout: FC<Props> = ({ children }: Props) => {
  const { private: privateRoute, noLayout } = useCurrentRoute();
  const theme = THEMES[0];

  if (!privateRoute || noLayout) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={styles.ComponentContainer}>{children}</div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
        <Sidebar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: {
              xs: '100%',
              md: `calc(100% - ${drawerWidth}px)`,
            },
            maxHeight: '100vh',
            // no scroll
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'backgroundGrey.main',
              minHeight: '100vh',
              width: '100%',
              overflow: 'auto',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  children: undefined,
};

export default Layout;
