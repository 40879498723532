import React, { FC, useState } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import InventoryIcon from '@mui/icons-material/Inventory';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { onMessage } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import { useArchiveAlertMutation, useGetAlertsQuery, useReadAlertMutation } from 'services/api/alerts.api';
import type { Alert } from 'services/types/alert';

/* Inspired by https://mui.com/store/items/mantis-free-react-admin-dashboard-template/ */
const Alerts: FC<{ businessUnitId?: number }> = ({ businessUnitId }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: alerts, refetch: refetchAlerts } = useGetAlertsQuery({ businessUnitId });
  const [readAlert] = useReadAlertMutation();
  const [archiveAlert] = useArchiveAlertMutation();

  const read = alerts?.filter((a) => a.readAt === null).length || 0;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const alertsByBusinessUnit = alerts?.reduce(
    (acc, alert) => {
      const businessUnit = alert.businessUnit?.name || '';
      if (!acc[businessUnit]) {
        acc[businessUnit] = [];
      }
      acc[businessUnit].push(alert);
      return acc;
    },
    {} as Record<string, Alert[]>
  );

  if (messaging) {
    onMessage(messaging, (payload) => {
      // eslint-disable-next-line no-console
      console.log('Message received. Alerts will be refetched', payload);
      refetchAlerts().catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    });
  }

  return (
    <Box>
      <IconButton sx={{ color: 'text.primary' }} onClick={handleClick}>
        <Badge badgeContent={read} color="primary">
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Card elevation={0}>
              <CardHeader sx={{ p: 2.5 }} titleTypographyProps={{ variant: 'subtitle1' }} title="Notificaciones" />
              <List
                sx={{
                  px: 0,
                  py: 0.5,
                  '& .MuiListItemButton-root': {
                    '& .MuiListItemIcon-root': { width: '30px' },
                  },
                }}
              >
                {alertsByBusinessUnit &&
                  Object.keys(alertsByBusinessUnit)
                    .sort((a, b) => a.localeCompare(b))
                    .map((businessUnit) => {
                      return (
                        <>
                          {Object.keys(alertsByBusinessUnit).length > 1 && (
                            <ListSubheader key={businessUnit} sx={{ textAlign: 'center', backgroundColor: '#f0f0f0' }}>
                              <Typography fontWeight={500}>{businessUnit}</Typography>
                            </ListSubheader>
                          )}
                          {alertsByBusinessUnit[businessUnit].map((alert) => (
                            <Box key={alert.id}>
                              <ListItemButton
                                selected={alert.readAt === null}
                                aria-label="marcar como leída"
                                onClick={() => {
                                  if (alert.readAt === null) {
                                    readAlert({ alertId: alert.id, readAlert: true })
                                      .then(() => {
                                        // eslint-disable-next-line no-console
                                        refetchAlerts().catch((e) => console.error(e));
                                      })
                                      // eslint-disable-next-line no-console
                                      .catch((e) => console.error(e));
                                  }
                                }}
                              >
                                {alert.readAt === null && (
                                  <ListItemIcon sx={{ minWidth: '15px' }}>
                                    <CircleIcon sx={{ fontSize: '10px' }} color="primary" />
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  primary={<Typography variant="h6">{alert.identifier}</Typography>}
                                  secondary={new Date(alert.createdAt).toLocaleTimeString('es-CL', {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false,
                                  })}
                                />
                                <ListItemIcon sx={{ minWidth: '15px' }}>
                                  <IconButton
                                    edge="end"
                                    aria-label="archivar"
                                    onClick={() => {
                                      archiveAlert({ alertId: alert.id, archiveAlert: true })
                                        .then(() => {
                                          // eslint-disable-next-line no-console
                                          refetchAlerts().catch((e) => console.error(e));
                                        })
                                        // eslint-disable-next-line no-console
                                        .catch((e) => console.error(e));
                                    }}
                                  >
                                    <InventoryIcon />
                                  </IconButton>
                                </ListItemIcon>
                              </ListItemButton>
                              <Divider />
                            </Box>
                          ))}
                        </>
                      );
                    })}
                {alerts?.length === 0 && (
                  <ListItemButton>
                    <ListItemText primary="No hay notificaciones" />
                  </ListItemButton>
                )}
              </List>
            </Card>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </Box>
  );
};

Alerts.defaultProps = {
  businessUnitId: undefined,
};

export default Alerts;
