import React, { FC } from 'react';

import { Box, Grid, CircularProgress, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';

import {
  BusinessUnitClientsMetrics,
  BusinessUnitMessagesMetrics,
  BusinessUnitResponseTimesMetrics,
  BusinessUnitFollowUpResponseTimesMetrics,
} from 'services/types/metrics';

import ChartCard from './ChartCard';

const minutesToString = (minutes: number | string) => {
  const mins = Number(minutes);
  if (mins < 60) {
    return `<${mins}m`;
  }
  if (mins < 1440) {
    return `<${Math.floor(mins / 60)}h`;
  }
  return `<${Math.floor(mins / 1440)}d`;
};

interface ChartProps {
  isAdmin: boolean;
  businessUnitType?: string;
  clientMetrics?: BusinessUnitClientsMetrics;
  messagesMetrics?: BusinessUnitMessagesMetrics;
  responseTimesMetrics?: BusinessUnitResponseTimesMetrics;
  followUpResponseTimesMetrics?: BusinessUnitFollowUpResponseTimesMetrics;
  conversationTopics?: Record<string, number>;
  endReasons?: Record<string, number>;
}

const Charts: FC<ChartProps> = ({
  isAdmin,
  businessUnitType,
  clientMetrics,
  messagesMetrics,
  responseTimesMetrics,
  followUpResponseTimesMetrics,
  conversationTopics,
  endReasons,
}: ChartProps) => {
  const clientsBySourceForChart = { ...(clientMetrics?.chartsData.clientsBySource || {}) };
  if (Object.keys(clientsBySourceForChart).length > 25) {
    const whatsappOthersKey = 'whatsapp-otros';
    clientsBySourceForChart[whatsappOthersKey] = 0;
    while (Object.keys(clientsBySourceForChart).length > 15) {
      const minKey = Object.keys(clientsBySourceForChart)
        .filter((key) => key.includes('whatsapp') && key !== whatsappOthersKey)
        .reduce(
          (a, b) => (clientsBySourceForChart[a] < clientsBySourceForChart[b] ? a : b),
          Object.keys(clientsBySourceForChart)[0]
        );
      clientsBySourceForChart[whatsappOthersKey] += clientsBySourceForChart[minKey];
      delete clientsBySourceForChart[minKey];
    }
  }
  const sourceChartData = Object.entries(clientsBySourceForChart).map((source, index) => ({
    id: index,
    value: source[1],
    label: source[0].length > 43 ? `${source[0].slice(0, 20)}...${source[0].slice(-20)}` : source[0],
  }));

  const brandChartData = Object.entries(clientMetrics?.chartsData.clientsByBrand || {}).map((brand, index) => ({
    id: index,
    value: brand[1],
    label: brand[0],
  }));

  const clientsByMessagesCount = { ...(clientMetrics?.chartsData.clientsByMessagesCount || {}) };
  delete clientsByMessagesCount[0];
  delete clientsByMessagesCount[1];
  const clientsByResponseTime = { ...(responseTimesMetrics?.chartsData.clientsByResponseTime || {}) };
  delete clientsByResponseTime[0];

  return (
    <Box>
      <Grid container direction="column">
        <ChartCard
          title="Clientes por día"
          description={
            businessUnitType === 'financial_advisor'
              ? 'Clientes que se crean cada día. Ej: 03-24 (S) es el Sábado 24 de marzo'
              : "Clientes que se crean cada día. Ej: 03-24 (S) es el Sábado 24 de marzo. 'Clientes en CRM' son los que se suben al sistema de la empresa"
          }
        >
          {clientMetrics ? (
            <LineChart
              series={[
                { data: Object.values(clientMetrics.chartsData.clientsByCreationDay), label: 'Clientes' },
                ...(businessUnitType === 'car_dealership'
                  ? [
                      {
                        data: Object.values(clientMetrics.chartsData.clientsUploadedToCRMByCreationDay),
                        label: 'Clientes en CRM',
                        valueFormatter: (value: number | null, { dataIndex }: { dataIndex: number }) => {
                          return `${value} (${(
                            ((value || 0) * 100) /
                            (Object.values(clientMetrics.chartsData.clientsByCreationDay)[dataIndex] || 1)
                          ).toFixed(1)}%)`;
                        },
                      },
                      {
                        data: Object.values(clientMetrics.chartsData.clientsWithVisitDateByCreationDay),
                        label: 'Clientes con visita agendada',
                        valueFormatter: (value: number | null, { dataIndex }: { dataIndex: number }) => {
                          return `${value} (${(
                            ((value || 0) * 100) /
                            (Object.values(clientMetrics.chartsData.clientsByCreationDay)[dataIndex] || 1)
                          ).toFixed(1)}%)`;
                        },
                      },
                    ]
                  : []),
                ...Object.entries(clientMetrics.chartsData.clientsByCreditStatusByCreationDay).map(
                  ([status, data]) => ({
                    data: Object.values(data),
                    label: status,
                    valueFormatter: (value: number | null, { dataIndex }: { dataIndex: number }) => {
                      return `${value} (${(
                        ((value || 0) * 100) /
                        (Object.values(clientMetrics.chartsData.clientsByCreationDay)[dataIndex] || 1)
                      ).toFixed(1)}%)`;
                    },
                  })
                ),
              ]}
              height={400}
              xAxis={[
                {
                  scaleType: 'band',
                  data: Object.keys(clientMetrics.chartsData.clientsByCreationDay),
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    fontSize: 12,
                  },
                },
              ]}
              yAxis={[
                {
                  label: 'Cantidad de clientes',
                  min: 0,
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 60, left: 40, right: 80 }}
              title="Clientes por hora de entrada"
              slotProps={{
                legend: {
                  position: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                },
              }}
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>

        {businessUnitType === 'car_dealership' && (
          <ChartCard title="Clientes por marca" description="Clientes que se crean por marca">
            {clientMetrics ? (
              <PieChart
                skipAnimation
                label="Clientes por marca"
                title="Clientes por marca"
                series={[
                  {
                    data: brandChartData,
                  },
                ]}
                height={(Math.floor(brandChartData.length / 5) + 1) * 200}
              />
            ) : null}
          </ChartCard>
        )}

        <ChartCard title="Clientes por origen" description="Gráfico con la fuente de los clientes">
          {clientMetrics ? (
            <PieChart
              skipAnimation
              label="Clientes por origen"
              title="Clientes por origen"
              series={[
                {
                  data: sourceChartData,
                },
              ]}
              height={(Math.floor(sourceChartData.length / 5) + 1) * 200}
            />
          ) : null}
        </ChartCard>
        {clientMetrics && clientMetrics.chartsData.clientsByCreditStatus && businessUnitType === 'car_dealership' ? (
          <ChartCard
            title="Clientes por estado de crédito"
            description="Clientes según el estado de crédito luego de la revisión de la financiera"
          >
            <PieChart
              skipAnimation
              label="Clientes por estado de crédito"
              title="Clientes por estado de crédito"
              series={[
                {
                  data: Object.entries(clientMetrics.chartsData.clientsByCreditStatus).map((creditStatus, index) => ({
                    id: index,
                    value: creditStatus[1],
                    label: creditStatus[0],
                  })),
                },
              ]}
              height={200}
            />
          </ChartCard>
        ) : null}
        {businessUnitType === 'car_dealership' &&
        clientMetrics &&
        clientMetrics.chartsData.clientsByOutboundCampaign ? (
          <ChartCard title="Clientes por campaña de outbound" description="Clientes creados por campaña de outbound">
            <PieChart
              skipAnimation
              label="Clientes por campaña de outbound"
              title="Clientes por campaña de outbound"
              series={[
                {
                  data: Object.entries(clientMetrics.chartsData.clientsByOutboundCampaign).map((campaign, index) => ({
                    id: index,
                    value: campaign[1],
                    label: campaign[0],
                  })),
                },
              ]}
              height={200}
            />
          </ChartCard>
        ) : null}

        <ChartCard
          title="Clientes por horario de llegada"
          description="Clientes que llegan cada hora. Ej: El valor 15 corresponde a la cantidad de clientes que se crean entre las 15:00 y las 15:59"
        >
          {clientMetrics ? (
            <LineChart
              series={[{ data: Object.values(clientMetrics.chartsData.clientsByCreationHour) }]}
              height={290}
              xAxis={[{ data: Object.keys(clientMetrics.chartsData.clientsByCreationHour), scaleType: 'band' }]}
              yAxis={[
                {
                  label: 'Cantidad de clientes',
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
              title="Clientes por hora de entrada"
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>

        <ChartCard
          title="Mensajes por día"
          description="Mensajes que se enviaron cada día. Ej: 03-24 (S) es el Sábado 24 de marzo"
        >
          {messagesMetrics ? (
            <BarChart
              series={[
                {
                  data: Object.values(messagesMetrics.chartsData.messagesPerDayInOfficeHours),
                  stack: '1',
                  label: 'Horario Laboral',
                },
                {
                  data: Object.values(messagesMetrics.chartsData.messagesPerDayOutOfOfficeHours),
                  stack: '1',
                  label: 'Fuera de Horario Laboral',
                },
              ]}
              height={400}
              xAxis={[
                {
                  data: Object.keys(messagesMetrics.chartsData.messagesPerDayInOfficeHours),
                  scaleType: 'band',
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    fontSize: 12,
                  },
                },
              ]}
              margin={{ top: 10, bottom: 60, left: 40, right: 80 }}
              colors={['#6499e9', '#f02b2c']}
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>
        <ChartCard
          title="Mensajes por hora"
          description="Mensajes que se envían cada hora. Ej: El valor 15 corresponde a la cantidad de mensajes enviados entre las 15:00 y las 15:59"
        >
          {messagesMetrics ? (
            <LineChart
              series={[{ data: Object.values(messagesMetrics.chartsData.messagesByCreationHour) }]}
              height={290}
              xAxis={[{ data: Object.keys(messagesMetrics.chartsData.messagesByCreationHour), scaleType: 'band' }]}
              yAxis={[
                {
                  label: 'Cantidad de mensajes',
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
              title="Mensajes que se envían cada hora"
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>

        <ChartCard
          title="Cantidad de mensajes que envían los clientes"
          description="Ignora los clientes con teléfonos inválidos (0 mensajes) y también los que nunca contestaron (1 mensaje)"
        >
          {clientMetrics ? (
            <BarChart
              series={[{ data: Object.values(clientsByMessagesCount) }]}
              height={290}
              xAxis={[{ data: Object.keys(clientsByMessagesCount), scaleType: 'band' }]}
              yAxis={[
                {
                  label: 'Cantidad de clientes',
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
              title="Clientes por cantidad de mensajes"
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>
        <ChartCard
          title="Tiempo de primera respuesta de los clientes"
          description="Solo considera clientes en que Zeller envía el primer mensaje y que luego contestan. Para los que no contestan ver el indicador del inicio. Para las conversaciones iniciadas por los clientes, ver el gráfico de 'Clientes por Origen' y revisar los que dicen origen 'whatsapp'"
        >
          {responseTimesMetrics ? (
            <BarChart
              series={[
                {
                  data: Object.values(clientsByResponseTime).map(
                    (value) =>
                      (value * 100) / (Object.values(clientsByResponseTime).reduce((acc, val) => acc + val, 0) || 1)
                  ),
                },
              ]}
              height={290}
              xAxis={[{ data: Object.keys(clientsByResponseTime).map(minutesToString), scaleType: 'band' }]}
              yAxis={[
                {
                  label: 'Porcentaje de clientes',
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
              title="Tiempo de respuestas de los clientes"
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>

        {businessUnitType === 'car_dealership' && (
          <ChartCard
            title="Tiempo de respuestas de los clientes a los followUps"
            description="Gráfico 1: Tiempo de respuesta a mensajes de follow up. 
            Gráfico 2: Cantidad de followUps por cada tipo. Si el mensaje no tiene respuesta en 48 hrs se considera como 'No responde'. 
            Toma en cuenta los mensajes de follow ups enviados en el rango de fechas seleccionado"
          >
            {followUpResponseTimesMetrics &&
            isAdmin &&
            followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.length > 0 ? (
              <Box display="flex" mt={2}>
                <Box sx={{ flex: 3 }}>
                  <BarChart
                    series={followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.map((followUp) => ({
                      data: [
                        followUp.respond10Minutes,
                        followUp.respond1Hour - followUp.respond10Minutes,
                        followUp.respond6Hour - followUp.respond1Hour,
                        followUp.respond24Hour - followUp.respond6Hour,
                        followUp.respond48Hour - followUp.respond24Hour,
                        followUp.didnotRespond,
                      ],
                      label: followUp.followUpConfigId,
                    }))}
                    height={290}
                    xAxis={[{ data: ['<10m', '<1hr', '<6hr', '<24hr', '<48hr', 'No responde'], scaleType: 'band' }]}
                    yAxis={[
                      {
                        label: 'Cantidad de clientes',
                      },
                    ]}
                    margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    title="Tiempo de respuestas de los clientes a los followUps"
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <BarChart
                    series={[
                      {
                        data: followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.map(
                          (followUp) => followUp.totalClients - followUp.didnotRespond
                        ),
                        label: 'Clientes que responden',
                        stack: '1',
                      },
                      {
                        data: followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.map(
                          (followUp) => followUp.didnotRespond
                        ),
                        label: 'Clientes sin respuesta',
                        stack: '1',
                      },
                    ]}
                    height={290}
                    xAxis={[
                      {
                        data: followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.map(
                          (followUp) => followUp.followUpConfigId
                        ),
                        scaleType: 'band',
                      },
                    ]}
                    yAxis={[
                      {
                        label: 'Cantidad de followUps',
                      },
                    ]}
                    margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                    title="Cantidad de followUps enviados"
                  />
                </Box>
              </Box>
            ) : (
              <Typography mt={4}>No disponible todavía</Typography>
            )}
          </ChartCard>
        )}
        {conversationTopics && (
          <ChartCard
            title="Temas de conversación"
            description="Temas hablados por los clientes. Puede haber más de un tema por cliente (conversación)"
          >
            <BarChart
              series={[
                {
                  data: conversationTopics ? Object.values(conversationTopics) : [],
                },
              ]}
              height={290}
              xAxis={[
                {
                  data: conversationTopics ? Object.keys(conversationTopics) : [],
                  scaleType: 'band',
                },
              ]}
              yAxis={[
                {
                  label: 'Cantidad de conversaciones',
                  min: 0,
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
            />
          </ChartCard>
        )}

        {endReasons && (
          <ChartCard title="Motivos de término" description="Motivos de término de conversaciones">
            <BarChart
              series={[
                {
                  data: endReasons ? Object.values(endReasons) : [],
                },
              ]}
              height={290}
              xAxis={[
                {
                  data: endReasons ? Object.keys(endReasons) : [],
                  scaleType: 'band',
                },
              ]}
              yAxis={[
                {
                  label: 'Cantidad de conversaciones',
                  min: 0,
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
            />
          </ChartCard>
        )}
      </Grid>
    </Box>
  );
};

Charts.defaultProps = {
  clientMetrics: undefined,
  messagesMetrics: undefined,
  responseTimesMetrics: undefined,
  followUpResponseTimesMetrics: undefined,
  businessUnitType: undefined,
  conversationTopics: undefined,
  endReasons: undefined,
};

export default Charts;
