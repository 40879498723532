import { FunctionComponent, lazy } from 'react';

import Admin from 'pages/Admin';
import Analyses from 'pages/Analyses';
import Analysis from 'pages/Analysis';
import BusinessUnitCharts from 'pages/BusinessUnitCharts';
import BusinessUnitConfig from 'pages/BusinessUnitConfig';
import BusinessUnits from 'pages/BusinessUnits';
import Campaigns from 'pages/Campaigns';
import Cars from 'pages/Cars';
import Client from 'pages/Client';
import ClientQuestions from 'pages/ClientQuestions';
import Clients from 'pages/Clients';
import Dashboard from 'pages/Dashboard';
import DbReader from 'pages/DbReader';
import Demochat from 'pages/Demochat';
import NewDemochat from 'pages/Demochat/NewChat';
import ErrorPage from 'pages/Error/ErrorPage';
import Evals from 'pages/Evals';
import FollowUpConfig from 'pages/FollowUpConfig';
import LandingPage from 'pages/LandingPage';
import Playground from 'pages/Playground';
import Profile from 'pages/Profile';
import PromptSections from 'pages/PromptSections';
import PublicChat from 'pages/PublicChat';
import SignIn from 'pages/SignIn';
import ThemeDemo from 'pages/ThemeDemo';
import TyC from 'pages/TyC';
import Unauthorized from 'pages/Unauthorized';
import Users from 'pages/Users';
import WhatsAppIntegrationSuccess from 'pages/WhatsAppIntegrationSuccess';
import WidgetChat from 'pages/WidgetChat';
import NewWidgetChat from 'pages/WidgetChat/NewChat';

const Icons = lazy(() => import('pages/Icons'));

export type Route = {
  title: string;
  path: string;
  regex?: RegExp;
  component: FunctionComponent;
  private: boolean;
  index: boolean;
  noLayout?: boolean;
  role?: 'admin' | 'businessUnitAdmin' | 'branchManager' | 'seller';
};

const routes: { [key: string]: Route } = {
  '/sign-in': {
    title: 'Iniciar Sesión',
    path: '/sign-in',
    component: SignIn,
    private: false,
    index: false,
  },
  '/dashboard': {
    title: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    private: true,
    index: false,
    role: 'branchManager',
  },
  '/': {
    title: 'Bienvenido',
    path: '/',
    component: LandingPage,
    private: false,
    index: false,
    noLayout: true,
  },
  '/admin': {
    title: 'Administrador',
    path: '/admin',
    component: Admin,
    private: true,
    index: false,
    role: 'admin',
  },
  '/clients/:clientId': {
    title: 'Chat WhatsApp',
    path: '/clients/:clientId',
    regex: /\/clients\/\d+/,
    component: Client,
    private: true,
    index: false,
    role: 'seller',
  },
  '/businessUnits': {
    title: 'Empresas',
    path: '/businessUnits',
    component: BusinessUnits,
    private: true,
    index: false,
    role: 'admin',
  },
  '/businessUnit-follow-up-config/:businessUnitId': {
    title: 'Follow Ups',
    path: '/businessUnit-follow-up-config/:businessUnitId',
    regex: /\/businessUnit-follow-up-config\/\d+/i,
    component: FollowUpConfig,
    private: true,
    index: false,
    role: 'admin',
  },
  '/businessUnit-config/:businessUnitId': {
    title: 'Empresa Configuraciones',
    path: '/businessUnit-config/:businessUnitId',
    regex: /\/businessUnit-config\/\d+/i,
    component: BusinessUnitConfig,
    private: true,
    index: false,
    role: 'admin',
  },
  '/businessUnit-charts/:businessUnitId': {
    title: 'Empresa Gráficos',
    path: '/businessUnit-charts/:businessUnitId',
    regex: /\/businessUnit-charts\/\d+/i,
    component: BusinessUnitCharts,
    private: true,
    index: false,
    role: 'admin',
  },
  '/analysis': {
    title: 'Análisis',
    path: '/analysis',
    component: Analyses,
    private: true,
    index: false,
    role: 'branchManager',
  },
  '/analysis/:analysisId': {
    title: 'Análisis',
    path: '/analysis/:analysisId',
    regex: /\/analysis\/\d+/,
    component: Analysis,
    private: true,
    index: false,
    role: 'branchManager',
  },
  '/cars': {
    title: 'Autos',
    path: '/cars',
    component: Cars,
    private: true,
    index: false,
    role: 'admin',
  },
  '/information': {
    title: 'Información',
    path: '/information',
    component: PromptSections,
    private: true,
    index: false,
    role: 'businessUnitAdmin',
  },
  '/users': {
    title: 'Usuarios',
    path: '/users',
    component: Users,
    private: true,
    index: false,
    role: 'admin',
  },
  '/clients': {
    title: 'Clientes',
    path: '/clients',
    component: Clients,
    private: true,
    index: false,
    role: 'seller',
  },
  '/icons': {
    title: 'Icons',
    path: '/icons',
    component: Icons,
    private: true,
    index: false,
    role: 'admin',
  },
  '/unauthorized': {
    title: 'Unauthorized',
    path: '/unauthorized',
    component: Unauthorized,
    private: true,
    index: false,
    role: 'seller',
  },
  '/tyc': {
    title: 'Términos y Condiciones',
    path: '/tyc',
    component: TyC,
    private: false,
    index: false,
  },
  '/db-reader': {
    title: 'Analizador de Base de Datos',
    path: '/db-reader',
    component: DbReader,
    private: true,
    index: false,
    role: 'admin',
  },
  '/playground': {
    title: 'Playground',
    path: '/playground/:messageId',
    regex: /\/playground\/\d+/,
    component: Playground,
    private: true,
    index: false,
    noLayout: true,
    role: 'admin',
  },
  '/publicChat': {
    title: 'Public Chat',
    path: '/publicChat',
    component: PublicChat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/WhatsAppIntegrationSuccess': {
    title: 'Zeller Integración WhatsApp',
    path: '/WhatsAppIntegrationSuccess',
    component: WhatsAppIntegrationSuccess,
    private: false,
    index: false,
    noLayout: true,
  },
  '/demochat': {
    title: 'Demo Chat',
    path: '/demochat',
    component: ErrorPage,
    private: false,
    index: false,
    noLayout: true,
  },
  '/demochat/:companyIdentifier': {
    title: 'Demo Chat',
    path: '/demochat/:companyIdentifier',
    component: Demochat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/demochat/:companyIdentifier/newchat': {
    title: 'New Demo Chat',
    path: '/demochat/:companyIdentifier/newchat',
    component: NewDemochat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/widgetChat': {
    title: 'Widget Chat',
    path: '/widgetChat',
    component: WidgetChat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/widgetChat/:companyIdentifier': {
    title: 'Widget Chat',
    path: '/widgetChat/:companyIdentifier',
    component: WidgetChat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/widgetChat/:companyIdentifier/newChat': {
    title: 'New Widget Chat',
    path: '/widgetChat/:companyIdentifier/newChat',
    component: NewWidgetChat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/theme': {
    title: 'Theme Demo',
    path: '/theme',
    component: ThemeDemo,
    private: true,
    index: false,
    noLayout: true,
    role: 'admin',
  },
  '/evals': {
    title: 'Evals',
    path: '/evals',
    component: Evals,
    private: true,
    index: false,
    role: 'admin',
  },
  '/clientQuestions': {
    title: 'Preguntas Clientes',
    path: '/clientQuestions',
    component: ClientQuestions,
    private: true,
    index: false,
    role: 'admin',
  },
  '/profile': {
    title: 'Perfil',
    path: '/profile',
    component: Profile,
    private: true,
    index: false,
    role: 'seller',
  },
  '/campaigns': {
    title: 'Campañas',
    path: '/campaigns',
    component: Campaigns,
    private: true,
    index: false,
    role: 'admin',
  },
  // this path must be the last one
  error: {
    title: 'Error',
    path: '*',
    component: ErrorPage,
    private: true,
    index: false,
    role: 'seller',
  },
};

export default routes;
